/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Bookshelf module index file
 *
 * @file index.js
 * @author Vignesh Selvam, Arun Srinivasan
 */

import React, { Suspense } from 'react';
import Framework from '@greenville/framework';
import env from '../../common/env';
import CommonUtils from '../../common/utils/CommonUtils';
import PopularChannelsSTGData from '../../assets/data/popularChannelsSTG.json';
import PopularChannelsPRODData from '../../assets/data/popularChannelsPROD.json';
import { AUTHORIZATION, X_AUTHORIZATION } from '../../common/constants';
import RemoveEntitlement from './services/RemoveEntitlement';
import DisplayOrderService from './service/DisplayOrderService';
import LdUserHash from './model/LdUserHash';
import UserPreferencesService from './service/UserPreferencesService';
import LastLocationService from '../bookshelf/services/LastLocationService';
import BookshelfService from '../bookshelf/services/BookshelfService';
import Bookshelf from '../bookshelf/model/Bookshelf';
import CourseCollection from './model/CourseCollection';
import LastLocation from '../bookshelf/model/LastLocation';
import HeroBanner from './model/HeroBanner';
import LdUserHashService from './service/LdUserHashService';
import ChannelRecommendationsByBookService from './service/ChannelRecommenationsByBookService';
import ChannelRecommendationsByChannelService from './service/ChannelRecommenationsByChannelService';
import ChannelRecommendationsByBook from './model/ChannelRecommendationsByBook';
import ChannelRecommendationsByChannel from './model/ChannelRecommendationsByChannel';
import PopularChannelsService from './service/PopularChannelsService';
import CourseCollectionService from './service/CourseCollectionService';
import BookCollectionService from './service/BookCollectionService';
import ChannelTOCService from './service/ChannelTOCService';
import BookPreferencesService from './service/BookPreferencesService';
import { MainContainerLoader } from './Loader/ComponentLoader';

const AuthHomeContainer = React.lazy(() => import('./AuthHomeContainer'));

Framework.getClientManager().add({
  name: 'eligibility',
  authHeader: X_AUTHORIZATION,
  timeout: env.ELIGIBILITY_API_TIMEOUT,
  baseUrl: env.ELIGIBILITY_API_BASE_URL,
  tokenPrefix: '',
  addCorrelationId: false
}).add({
  name: 'studyChannel',
  authHeader: AUTHORIZATION,
  timeout: env.CHANNELS_MAPPING_URL_TIMEOUT,
  baseUrl: env.CHANNELS_MAPPING_URL,
  tokenPrefix: '',
  addCorrelationId: false
});

/**
 * Function to get initial popular channels
 * @returns
 */
const getInitialPopularChannels = () => {
  let response = PopularChannelsSTGData;

  if (env.ENVIRONMENT === 'prod') {
    response = PopularChannelsPRODData;
  }
  const { courses } = response;
  return CommonUtils.constructPopularChannelsData(courses);
};


export default {
  path: ['/home', '/home/:expandContainer'],
  exact: true,
  services: [
    new RemoveEntitlement(),
    new DisplayOrderService(),
    new UserPreferencesService(),
    new BookshelfService(),
    new LastLocationService(),
    new LdUserHashService(),
    new ChannelRecommendationsByBookService(),
    new ChannelRecommendationsByChannelService(),
    new PopularChannelsService(),
    new CourseCollectionService(),
    new BookCollectionService(),
    new ChannelTOCService(),
    new BookPreferencesService()
  ],
  stores: [
    {
      name: 'coursecollection',
      spec: CourseCollection,
      saveLocally: false,
      initialState: {
        courses: [],
        activeTab: ''
      }
    },
    {
      name: 'bookshelf',
      spec: Bookshelf,
      saveLocally: false,
      initialState: {
        book_id: '',
        book_title: '',
        entitlement_source: '',
        description: '',
        url: '',
        cover_image_url: '',
        book_type: '',
        author: '',
        index_id: '',
        version: '',
        product_id: '',
        product_model: '',
        isbn: '',
        language: '',
        user_id: '',
        study_guide_available: false,
        audio_book_supported: '',
        courses: [{
          course_id: '',
          course_name: '',
          course_start_date: '',
          course_end_date: '',
          user_role: ''
        }],
        product_entitlements: {
          subscription_id: '',
          type: '',
          business_model_code: '',
          end_date: '',
          start_date: '',
          expired: false
        }
      }
    },
    {
      name: 'lastlocation',
      spec: LastLocation,
      saveLocally: false,
      initialState: {
        userLocation: [],
        channelLocation: [],
        status: '',
        message: ''
      }
    },
    {
      name: 'ldUserHash',
      spec: LdUserHash,
      saveLocally: false,
      initialState: {
        ldUserHash: ''
      }
    },
    {
      name: 'herobanner',
      spec: HeroBanner,
      saveLocally: false,
      initialState: {
        popularChannels: {
          isPopularChannelInitiated: false,
          channelCards: getInitialPopularChannels()
        },
        addTitleText: '',
        bannerType: '',
        callForAction: {},
        config: {},
        mappedChannel: {},
        subTitle: '',
        tableOfContents: {},
        thumbnailImage: '',
        title: '',
        titleInfo: {},
        tutor: {}
      }
    },
    {
      name: 'channelRecommendationsByBook',
      spec: ChannelRecommendationsByBook,
      saveLocally: false,
      initialState: {
        channelData: null,
        recommendedAssets: null
      }
    },
    {
      name: 'channelRecommendationsByChannel',
      spec: ChannelRecommendationsByChannel,
      saveLocally: false,
      initialState: {
        startWithTopics: null,
        jumpBackIn: null,
        recommendedNext: null
      }
    }
  ],
  render: props => (
    <Suspense fallback={<MainContainerLoader />}>
      <AuthHomeContainer {...props} />
    </Suspense>
  )
};
